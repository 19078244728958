jQuery(function () {
    $(window).on("scroll", function () {
        $("header").toggleClass("scrolling", $(window).scrollTop() > 0);
    });

    $(
        ".no-banner header, .product-detail-page header, .news-detail-page header"
    ).sticky({
        topSpacing: 0,
        zIndex: 999,
    });

    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $(".mega-toggle").on("click", function () {
        $(this).next().slideToggle();
        $(this)
            .find("span")
            .toggleText("keyboard_arrow_down", "keyboard_arrow_up");
    });

    $(".about-3 .item").on("click", function () {
        var index = $(this).data("index");
        $(".about-3 .item-hover").hide();
        $(`.about-3 .item-hover[data-index=${index}]`).show();
    });

    $(".expand-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).prev().toggleClass("show");
        $(this).find("strong").toggleText("Xem thêm", "Thu gọn");
        $(this)
            .find("span")
            .toggleText("keyboard_arrow_down", "keyboard_arrow_up");
    });

    productCategorySliderInit();
    if ($(window).width() > 1024) {
        $(window).resize(function () {
            $(".product-category-slider").css({
                "margin-left":
                    parseInt($(".home-2 .container").css("margin-left")) +
                    15 +
                    "px",
            });
        });
    }

    $.fancybox.defaults.parentEl = 'form'

    symbol01()
    symbol02()
    symbolOverlay() 

    setInterval(() => {
        symbol01()
        symbol02()
        symbolOverlay()
    }, 2000);

});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

try {
    const menuMapping = new MappingListener({
        selector: ".menu-wrapper",
        mobileWrapper: ".offcanvas-wrap",
        mobileMethod: "appendTo",
        desktopWrapper: ".logo-wrapper",
        desktopMethod: "insertAfter",
        breakpoint: 1024.98,
    }).watch();
} catch (error) {}

if ($(window).width() > 1024) {
    var timelineSlider = new Swiper(".timeline-slider .swiper-container", {
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
            prevEl: ".timeline-slider .swiper-btn-prev",
            nextEl: ".timeline-slider .swiper-btn-next",
        },
        breakpoints: {
            1280: {
                slidesPerView: 3,
            },
        },
    });
}

var productSlider = new Swiper(".product-slider .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: ".product-slider .swiper-btn-prev",
        nextEl: ".product-slider .swiper-btn-next",
    },
    breakpoints: {
        1280: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.3,
            spaceBetween: 15,
            centeredSlides: true,
        },
    },
});

var videoSlider = new Swiper(".video-slider .swiper-container", {
    centeredSlides: true,
    slidesPerView: 1.5,
    spaceBetween: -220,
    navigation: {
        prevEl: ".video-slider .swiper-btn-prev",
        nextEl: ".video-slider .swiper-btn-next",
    },
    breakpoints: {
        1024: {
            spaceBetween: 15,
            slidesPerView: 1.25,
        },
    },
});

function productCategorySliderInit() {
    if ($(window).width() > 1024) {
        $(".product-category-slider").css({
            "margin-left":
                parseInt($(".home-2 .container").css("margin-left")) +
                15 +
                "px",
        });
    }
    var productCategorySlider = new Swiper(
        ".product-category-slider .swiper-container",
        {
            slidesPerView: 5,
            spaceBetween: 30,
            navigation: {
                prevEl: ".product-category-slider .swiper-btn-prev",
                nextEl: ".product-category-slider .swiper-btn-next",
            },
            breakpoints: {
                1280: {
                    slidesPerView: 4,
                },
                1024: {
                    centeredSlides: true,
                    spaceBetween: 20,
                    slidesPerView: 3.5,
                },
                768: {
                    centeredSlides: true,
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
                576: {
                    centeredSlides: true,
                    spaceBetween: 20,
                    slidesPerView: 1.5,
                },
            },
        }
    );
}

var partnerSlider = new Swiper(".partner-slider .swiper-container", {
    slidesPerView: 6,
    spaceBetween: 30,
    autoplay: {
        delay: 2000
    },
    loop: true,
    navigation: {
        prevEl: ".partner-slider .swiper-btn-prev",
        nextEl: ".partner-slider .swiper-btn-next",
    },
    breakpoints: {
        1280: {
            slidesPerView: 5,
        },
        1024: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
    },
});

function symbol01() {
    var num1 = 41,num2 = 15,		
    percentage1 = Math.floor(Math.random()*(num1) + num2),
    percentage2 = Math.floor(Math.random()*(num1) + num2),
    percentage3 = Math.floor(Math.random()*(num1) + num2),
    percentage4 = Math.floor(Math.random()*(num1) + num2),
    percentage11 = 100 - percentage1,
    percentage21 = 100 - percentage2,
    percentage31 = 100 - percentage3,
    percentage41 = 100 - percentage4,

    borderRadius = percentage1 + '% ' + percentage11 + '% ' + percentage21 + '% ' + percentage2 + '% / ' + percentage3 + '% ' + percentage4 + '% ' + percentage41 + '% ' + percentage31 + '%';

    $(".symbol-1 .symbol-inner").css("border-radius", borderRadius);  
}

function symbol02() {
    var num1 = 46,num2 = 15,		
    percentage1 = Math.floor(Math.random()*(num1) + num2),
    percentage2 = Math.floor(Math.random()*(num1) + num2),
    percentage3 = Math.floor(Math.random()*(num1) + num2),
    percentage4 = Math.floor(Math.random()*(num1) + num2),
    percentage11 = 100 - percentage1,
    percentage21 = 100 - percentage2,
    percentage31 = 100 - percentage3,
    percentage41 = 100 - percentage4,

    borderRadius = percentage1 + '% ' + percentage11 + '% ' + percentage21 + '% ' + percentage2 + '% / ' + percentage3 + '% ' + percentage4 + '% ' + percentage41 + '% ' + percentage31 + '%';

    $(".symbol-2 .symbol-inner").css("border-radius", borderRadius);  
}

function symbolOverlay() {
    var num1 = 46,num2 = 11,		
    percentage1 = Math.floor(Math.random()*(num1) + num2),
    percentage2 = Math.floor(Math.random()*(num1) + num2),
    percentage3 = Math.floor(Math.random()*(num1) + num2),
    percentage4 = Math.floor(Math.random()*(num1) + num2),
    percentage11 = 100 - percentage1,
    percentage21 = 100 - percentage2,
    percentage31 = 100 - percentage3,
    percentage41 = 100 - percentage4,

    borderRadius = percentage1 + '% ' + percentage11 + '% ' + percentage21 + '% ' + percentage2 + '% / ' + percentage3 + '% ' + percentage4 + '% ' + percentage41 + '% ' + percentage31 + '%';

    $(".symbol .symbol-overlay").css("border-radius", borderRadius);  
}